.icon{
	background-image: url('../images/sprites.png');
	background-repeat:no-repeat;
	display: inline-block;
	vertical-align: middle;
	background-size: 80px 33px;

	&.owl-next{
		width: 35px;
		height: 33px;
		background-position: 0px 0px;
	}
	&.owl-prev{
		width: 35px;
		height: 33px;
		background-position: -45px 0px;
	}
}
