.topo{
	box-shadow: 0 0 10px rgba(0,0,0,0.3);

	.menu{
		list-style-type: none;
		padding-left: 0;
		margin-bottom: 0;
	}

	.redes{
		display: flex;
		align-items: center;

		a + a{
			margin-left: 5px;
		}

		a{
			display: flex;
			align-items: center;
			justify-content: center;
			width: 22px;
			height: 22px;
			border-radius: 50%;
			background-color: #fff;
			color: $verde;
			transition: all 0.2s linear;

			&:hover{
				background-color: $marron;
				color: #fff;
			}
		}
	}

	.faixa-topo-only-desktop{
		background-color: $verde;
		color: #fff;
		line-height: 1;
		font-size: 13px;
		padding: 8px 0;

		.container{
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		@include media-breakpoint-down(lg) {
			display: none;
		}
	}
	//.faixa-topo-only-desktop

	.campo-busca{
		button{
			width: 50px;
			height: 50px;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: $verde;
			color: #fff;
			line-height: 1;
			font-size: 24px;
			border: none;
			outline: none;
		}
		.form-control{
			font-size: 13px;
			color: #333;
			background-color: #eee;
			height: 50px;
			border-radius: 25px;
			border: none;
			width: 100%;
			padding-left: 55px;

			&:focus{
				box-shadow: none;
			}

			&::placeholder{
				color: #333;
			}
		}
	}
	//.campo-busca

	#campoBuscaResponsivo{
		position: fixed;
		top: 30px;
		padding: 15px;
		z-index: 1000;
		width: 350px;
		max-width: 100%;
		left: 50%;
		transform: translate(-50%, calc(-100% - 30px));
		transition: all 0.4s ease-out;
		color: #fff;
		text-align: center;

		&.show{
			transform: translate(-50%, 0);
		}

		label{
			font-size: 14px;
			line-height: 1;
			font-style: italic;
		}

		.group{
			position: relative;

			button{
				top: 0;
				left: 0;
				position: absolute;
			}
		}

		@include media-breakpoint-only(xl) {
			display: none;
		}

	}

	.main-navigation{

		.contatos{
			font-size: 14px;
			line-height: 1.2;
			color: #000;

			.midia{
				i.fas{
					width: 50px;
					height: 50px;
					border-radius: 50%;
					display: flex;
					align-items: center;
					justify-content: center;
					background-color: $verde;
					color: #fff;
					line-height: 1;
					font-size: 24px;
				}
				.title{
					color: #333333;
					font-family: $pps-bold;
					margin-bottom: 5px;
				}
				.txt{
					a + a{
						padding-left: 5px;
						margin-left: 5px;
						border-left: 1px solid #000;
					}
				}
			}
			@include media-breakpoint-only(xl) {
				.midia{
					display: flex;
					align-items: center;
					i.fas{
						margin-right: 10px;
					}
				}
			}
			@include media-breakpoint-down(lg) {
				.midia + .midia{
					margin-top: 15px;
				}
				.midia{
					text-align: center;
					i.fas{
						margin: 0 auto 10px auto;
					}
				}
			}
		}
		//contatos

		@include media-breakpoint-only(xl) {
			background-color: #fff;

			.meu-container{
				width: 100%;
				max-width: 1200px;
				margin-left: auto;
				margin-right: auto;
				padding: 0 15px;
			}
			.content{
				padding: 10px 0;
				border-bottom: 1px solid #ddd;

				.meu-container{
					display: flex;
					align-items: center;
					justify-content: space-between;

					.contatos{
						display: flex;
						align-items: center;
						flex-grow: 1;
						justify-content: space-between;
						margin: 0 50px;
					}
				}
				form{
					width: 300px;
					padding-left: 30px;
					border-left: 1px solid #ddd;

					.campo-busca{
						position: relative;
						button{
							position: absolute;
							top: 0;
							left: 0;
						}
					}
				}
				.down-lg{
					display: none;
				}
			}
			//content

			.menu{
				display: flex;
				justify-content: space-between;
				line-height: 1;
				font-size: 15px;

				li{
					display: flex;

					&.active a{
						font-family: $pps-bold;
						color: $marron;
					}

					a{
						display: flex;
						align-items: center;
						padding: 25px 5px;

						&:hover{
							font-family: $pps-bold;
							color: $marron;
						}
					}
				}
			}
		}
		//DESKTOP

		@include media-breakpoint-down(lg) {
			position: fixed;
			top: 0;
			left: -250px;
			width: 250px;
			height: 100%;
			overflow: auto;
			z-index: 1000;
			border-right: 1px solid #000;
			transition: left 0.4s ease-out;
			display: flex;
			flex-direction: column;
			background-color: #fff;
			padding-bottom: 15px;

			&.active{
				left: 0;
			}

			.logo,
			form{
				display: none;
			}

			.content{
				order: 2;
			}

			.menu{
				position: relative;
				margin-bottom: 15px;
				border-bottom: 1px solid $verde;

				li + li{
					border-top: 1px solid $verde;
				}

				&:before{
					content: 'MENU';
					position: relative;
					display: block;
					background-color: $marron;
					color: #fff;
					font-family: $pps-bold;
					text-align: center;
					font-size: 24px;
					padding: 10px;
					line-height: 1;
					border-bottom: 1px solid $verde;
				}

				.active a{
					background-color: $verde;
					color: #fff;
					font-family: $pps-bold;
				}

				a{
					display: block;
					padding: 10px 5px;
					line-height: 1.2;
				}
			}

			.down-lg{
				.endereco{
					margin-top: 15px;
					text-align: center;
					font-size: 14px;
					line-height: 1.2;
					i.fas{
						width: 50px;
						height: 50px;
						border-radius: 50%;
						display: flex;
						align-items: center;
						justify-content: center;
						background-color: $verde;
						color: #fff;
						line-height: 1;
						font-size: 24px;
						margin: 0 auto 15px auto;
					}
				}
				.redes{
					margin-top: 15px;
					justify-content: center;

					a{
						background-color: $verde;
						color: #fff;
						width: 30px;
						height: 30px;
						transition: all 0.2s linear;

						&:hover{
							background-color: $marron;
							color: #fff;
						}
					}
				}
			}
			//.down-lg
		}
	}
	//.main-navigation

	.mbl-controls{
		border-top: 5px solid $verde;

		.container{
			display: grid;

			.logo{
				padding: 5px 0;
			}

			.esquerda,
			.direita{
				display: flex;
			}
			.direita{
				justify-content: flex-end;
			}

			.botao{
				display: flex;
				align-items: center;
				background-color: $verde;
				width: 50px;
				color: #fff;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 20px;
				cursor: pointer;
			}
		}

		@include media-breakpoint-between(md, lg) {
			.container{
				grid-template-columns: calc(calc(100% - 178px)*0.5) 178px calc(calc(100% - 178px)*0.5);
			}
		}
		@include media-breakpoint-down(sm) {
			.container{
				grid-template-columns: calc(calc(100% - 140px)*0.5) 140px calc(calc(100% - 140px)*0.5);
			}
		}
		@include media-breakpoint-only(xs) {
			.container{
				padding: 0;
			}
		}

		@include media-breakpoint-only(xl) {
			display: none;
		}
	}
}
//.topo