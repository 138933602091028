.banners{
	width: 100%;
	max-width: 1920px;
	margin-left: auto;
	margin-right: auto;

	.carousel-indicators{
		right: auto;
		left: 0;
		margin-bottom: 0;
		display: flex;
		flex-direction: column;

		li + li{
			margin-top: 5px;
		}

		li{
			border: none;
			opacity: 0.6;
			width: 13px;
			height: 13px;
			border-radius: 50%;
			margin-left: 0;
			margin-right: 0;

			&.active{
				opacity: 1;
			}
		}

		@include media-breakpoint-only(xl) {
			margin-left: calc(calc(100vw - 1170px)/2);
			margin-right: calc(calc(100vw - 1170px)/2);
			bottom: 30px;
		}
		@include media-breakpoint-only(lg) {
			margin-left: calc(calc(100vw - 940px)/2);
			margin-right: calc(calc(100vw - 940px)/2);
			bottom: 20px;
		}
		@include media-breakpoint-only(md) {
			margin-left: calc(calc(100vw - 690px)/2);
			margin-right: calc(calc(100vw - 690px)/2);
			bottom: 10px;
		}
		@include media-breakpoint-only(sm) {
			margin-left: calc(calc(100vw - 510px)/2);
			margin-right: calc(calc(100vw - 510px)/2);
			bottom: 10px;
		}
		@include media-breakpoint-only(xs) {
			display: none;
		}
	}
}
//banners

.podemos-ajudar{
	position: relative;
	overflow: hidden;

	&:before,
	&:after{
		content: '';
		position: absolute;
		width: 336px;
		height: 351px;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: 100% 100%;
		background-image: url('../images/triangulo.png');
	}
	&:before{
		top: -25px;
		
	}
	&:after{
		bottom: -130px;
	}
	.title-1{
		display: inline-block;
		position: relative;
		padding-left: 80px;

		&:before{
			content: '';
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			left: 0;
			height: 6px;
			width: 70px;
			background-color: currentColor;
		}
	}

	.desc{
		color: #333;
	}

	@include media-breakpoint-only(xl) {
		padding: 90px 0;
		&:before{
			margin-left: calc(calc(100vw - 1700px)/2);
			margin-right: calc(calc(100vw - 1700px)/2);
			right: 0;
		}
		&:after{
			margin-left: calc(calc(100vw - 1700px)/2);
			margin-right: calc(calc(100vw - 1700px)/2);
			left: 0;
		}
	}
	@include media-breakpoint-between(md, lg) {
		&:before{
			right: -150px;
		}
		&:after{
			left: -150px;
		}
	}
	@include media-breakpoint-only(lg) {
		padding: 70px 0;
	}
	@include media-breakpoint-only(md) {
		padding: 60px 0;
	}
	@include media-breakpoint-down(sm) {
		padding: 40px 0;
		&:before,
		&:after{
			display: none;
		}
	}
}
//.podemos-ajudar

.servicos{
	background-color: $verde;
	padding-top: 50px;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	margin-bottom: -170px;

	.title-2{
		color: #fff;
		text-align: center;
		margin-bottom: 0;

		&.dois{
			font-family: $pps-regular;
			margin-top: 5px;
			width: 100%;
			max-width: 700px;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 40px;
		}
	}

	.segura-carousel{
		position: relative;
		z-index: 5;

		#owlServicos{
			.item{
				padding: 7px;
			}

			@include media-breakpoint-up(sm) {
				.owl-nav{
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					width: 100%;

					.owl-prev,
					.owl-next{
						position: absolute;
						top: 0;
						transform: translateY(-50%);
					}
					.owl-prev{
						left: -20px;
					}
					.owl-next{
						right: -20px;
					}
				}
			}
			@include media-breakpoint-only(xs) {
				padding-top: 30px;

				.owl-nav{
					position: absolute;
					top: 0;
					left: 50%;
					transform: translateX(-50%);
					width: 100px;

					.owl-prev,
					.owl-next{
						position: absolute;
						top: 0;
						transform: translateY(-50%);
					}
					.owl-prev{
						left: 0px;
					}
					.owl-next{
						right: 0px;
					}
				}
			}
		}
		//#owlServicos
	}
}
//.servicos

.sobre-nos{
	padding-top: 200px;
	background-color: #fff;
	position: relative;
	z-index: 2;

	.title-1{
		display: inline-block;
		position: relative;
		padding-left: 80px;

		&:before{
			content: '';
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			left: 0;
			height: 6px;
			width: 70px;
			background-color: currentColor;
		}
	}

	.desc.editor{
		line-height: 1.6;
		text-align: justify;
	}

	.botoes{
		margin-top: 30px;

		@include media-breakpoint-only(xl) {
			justify-content: flex-end;
		}
		@include media-breakpoint-up(sm) {
			display: flex;
			align-items: center;
			
			a + a{
				margin-left: 30px;
			}
		}
		@include media-breakpoint-between(sm, lg) {
			justify-content: center;
		}
		@include media-breakpoint-only(xs) {
			a{
				margin-left: auto;
				margin-right: auto;
			}
			a + a{
				margin-top: 15px;
			}
		}
	}

	.duvidas{
		.title,
		.sub-title{
			line-height: 1.2;
		}
		.title{
			color: #333;
		}
		.sub-title{
			color: $verde;
			font-family: $pps-bold;
		}

		@include media-breakpoint-up(md) {
			.title,
			.sub-title{
				font-size: 35px;
			}
			.sub-title{
				margin-bottom: 40px;
			}
		}
		@include media-breakpoint-down(sm) {
			.title,
			.sub-title{
				font-size: 24px;
			}
			.sub-title{
				margin-bottom: 25px;
			}
			.col-md-4 + .col-md-4{
				margin-top: 15px;
			}
		}
	}

	@include media-breakpoint-only(xl) {
		background-position: center top;
		background-repeat: no-repeat;
		background-size: cover;
		padding-bottom: 100px;

		.col-xl-7{
			padding-top: 80px;
		}

		.duvidas{
			margin-top: 60px;
		}
	}

	@include media-breakpoint-down(lg) {
		background-image: none !important;
		padding-bottom: 50px;

		.duvidas{
			margin-top: 30px;
		}
	}
}