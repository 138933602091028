.card-diferenciais{
	color: #333;
	line-height: 1.2;
	margin-top: 30px;

	@include media-breakpoint-only(xl) {
		display: flex;
		.img{
			margin-right: 10px;
		}
	}
	@include media-breakpoint-only(lg) {
		text-align: center;
		.img{
			margin-bottom: 10px;
			.lazyimage{
				margin-left: auto;
				margin-right: auto;
			}
		}
	}
	@include media-breakpoint-only(md) {
		display: flex;
		.img{
			margin-right: 10px;
		}
	}
	@include media-breakpoint-down(sm) {
		text-align: center;
		.img{
			margin-bottom: 10px;
			.lazyimage{
				margin-left: auto;
				margin-right: auto;
			}
		}
	}

	.nome{
		font-size: 18px;
		font-family: $pps-bold;
		margin-bottom: 5px;
	}
	.texto{
		font-size: 14px;
	}
}
//.card-diferenciais

.card-servicos{
	background-color: #eee;
	border-radius: 10px;
	padding: 45px 15px;
	box-shadow: 0 0 7px rgba(170,170,170,0.7);
	text-align: center;
	color: #333;
	line-height: 1.2;

	.img{
		margin-bottom: 20px;

		.lazyimage{
			border-radius: 50%;
			overflow: hidden;
			margin-left: auto;
			margin-right: auto;
			position: relative;

			&:after{
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: rgba(42,141,92,0.6);
				opacity: 0;
				transition: all 0.3s linear;
			}
		}
	}
	.nome{
		font-size: 17px;
		font-family: $pps-bold;
		margin-bottom: 10px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	.texto{
		font-size: 14px;
		margin-bottom: 20px;
		overflow: hidden;
		max-height: 100%;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		height: 48px;
		line-clamp: 3;
		-webkit-line-clamp: 3;
	}

	.botao{
		width: 180px;
		height: 48px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 24px;
		background-color: #ddd;
		font-family: $rly-bold;
		font-size: 13px;
		line-height: 1;
		margin-left: auto;
		margin-right: auto;
		transition: all 0.3s linear;
	}

	&:hover{
		.img{
			.lazyimage{
				&:after{
					opacity: 1;
				}
			}
		}
		.botao{
			background-color: $verde;
			color: #fff;
		}
	}
}
//.card-servicos

.card-numeros{
	width: 210px;
	max-width: 100%;
	margin-left: auto;
	margin-right: auto;
	text-align: center;

	svg{
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;

		circle{
			stroke-dasharray: 1000;
			stroke-dashoffset: 1000;
			transform: rotate(-90deg);
			transform-origin: center center;
		}
	}

	.circulo{
		position: relative;
		height: 210px;
		max-height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 10px;

		.number{
			position: relative;
			z-index: 10;
			color: $marron;
			line-height: 1;
			font-family: $pps-bold;
			font-size: 40px;
			letter-spacing: -2px;
		}
	}

	.nome{
		font-size: 17px;
		line-height: 1.2;
		color: #333;
		font-family: $rly-heavy;
		text-transform: uppercase;
	}	

	&.active{
		svg{
			circle{
				stroke-dashoffset: 0;
				transition: all 7s linear;
			}
		}
	}
}
//card-numeros

.card-treinamentos{
	display: flex;
	align-items: center;
	border: 1px solid #ccc;
	font-family: $rly-heavy;
	padding: 8px;
	background-color: #fff;
	transition: all 0.3s linear;

	&:hover{
		background-color: $verde;

		i.fas{
			color: #fff;
		}
		span.nome{
			color: #fff;
		}
	}
	
	i.fas{
		font-size: 26px;
		margin-right: 5px;
		color: $verde;
		transition: all 0.3s linear;
	}

	span.nome{
		font-size: 15px;
		text-transform: uppercase;
		color: #333;
		transition: all 0.3s linear;
	}
}
//.card-treinamentos

.card-noticias{
	width: 100%;
	max-width: 370px;
	margin-right: auto;
	margin-left: auto;
	text-align: center;
	color: #333;
	line-height: 1.4;
	margin-bottom: 30px;

	.img{
		margin-bottom: 10px;
		.lazyimage{
			border-radius: 10px;
			overflow: hidden;
		}
	}

	.nome{
		font-family: $pps-bold;
		font-size: 17px;
		margin-bottom: 5px;
		overflow: hidden;
		max-height: 100%;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		height: 46px;
		line-clamp: 2;
		-webkit-line-clamp: 2;
	}

	.texto{
		font-size: 14px;
		overflow: hidden;
		max-height: 100%;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		height: 38px;
		line-clamp: 2;
		-webkit-line-clamp: 2;
		margin-bottom: 10px;
	}

	.botao{
		font-size: 15px;
		font-family: $rly-heavy;
		border-bottom: 5px solid $verde;
		padding: 6px 25px;
		transition: all 0.3s linear;
	}

	&:hover{
		.botao{
			color: $verde;
		}
	}
}
//.card-noticias