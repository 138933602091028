.rodape{

	ul{
		list-style-type: none;
		padding-left: 0;
		margin-bottom: 0;
	}

	.content{
		background-color: $verde;
		padding: 30px 0;

		.titulos{
			text-transform: uppercase;
			font-family: $rly-heavy;
			line-height: 1.4;
			margin-bottom: 20px;

			.primeiro{
				font-size: 17px;
				color: #fff;

				@include media-breakpoint-only(lg) {
					font-size: 16px;
				}
			}
			.segundo{
				font-size: 17px;
				color: #D6D6D6;
			}

			&.webmail{
				margin-bottom: 15px;
				padding-top: 15px;
				margin-top: 15px;
				border-top: 1px solid #1D6342;
			}
		}

		.menu{
			font-size: 15px;
			line-height: 1;
			color: #fff;

			li + li{
				margin-top: 10px;
			}

			.active a{
				font-family: $pps-bold;
			}

			a{
				&:hover{
					font-family: $pps-bold;
				}
			}
		}

		.btn-padrao{
			font-size: 12px;
			background-color: #1D6342;
			outline: none;
			border: none;
			margin-top: 15px;

			&:hover{
				background-color: $marron;
			}
		}

		.form-group{
			margin-bottom: 10px;
		}

		input{
			height: 32px;
		}

		.form-control{
			border-radius: 0;
			border: 1px solid transparent;
			font-size: 11px;
			color: #000;
			font-family: $lato-regular;

			&::placeholder{
				color: #000;
			}
			&:focus{
				box-shadow: none;
				border-color: #000;
			}
		}

		.col-lg-5{
			.btn-padrao{
				margin-left: auto;
				margin-right: auto;
			}

			@include media-breakpoint-up(sm) {
				.col-sm-6:first-child{
					padding-right: 5px;
				}
				.col-sm-6:last-child{
					padding-left: 5px;
				}
			}
		}

		@include media-breakpoint-only(xl) {
			background-image: url('../images/bg-rodape.png');
			background-repeat: no-repeat;
			background-position: center bottom;
			.col-lg-4{
				padding-right: 120px;
			}
		}
		@include media-breakpoint-only(md) {
			.col-lg-5{
				margin-top: 30px;
			}
		}
		@include media-breakpoint-down(sm) {
			text-align: center;
			.btn-padrao{
				margin-left: auto;
				margin-right: auto;
			}
			.col-lg-4{
				margin-top: 30px;
				margin-bottom: 30px;
			}
		}
	}
	//.content

	.autorais{
		background-color: #1D6342;
		color: #fff;
		font-size: 13px;
		line-height: 1;
		font-family: $lato-regular;
		padding: 10px 0;

		a{
			display: flex;
			align-items: center;
			justify-content: center;

			img{
				margin-left: 10px;
			}
		}

		@include media-breakpoint-only(xl) {
			.container{
				display: flex;
				align-items: center;
				justify-content: center;

				a{
					margin-left: 10px;
				}
			}
		}
		@include media-breakpoint-down(lg) {
			.container{
				text-align: center;

				a{
					margin-top: 5px;
				}
			}
		}
	}
}