@font-face {
    font-family: 'latoregular';
    src: url('../fonts/lato-regular/lato-regular-webfont.eot');
    src: url('../fonts/lato-regular/lato-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/lato-regular/lato-regular-webfont.woff2') format('woff2'),
         url('../fonts/lato-regular/lato-regular-webfont.woff') format('woff'),
         url('../fonts/lato-regular/lato-regular-webfont.ttf') format('truetype'),
         url('../fonts/lato-regular/lato-regular-webfont.svg#latoregular') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'poppinsbold';
    src: url('../fonts/poppins-bold/poppins-bold-webfont.eot');
    src: url('../fonts/poppins-bold/poppins-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/poppins-bold/poppins-bold-webfont.woff2') format('woff2'),
         url('../fonts/poppins-bold/poppins-bold-webfont.woff') format('woff'),
         url('../fonts/poppins-bold/poppins-bold-webfont.ttf') format('truetype'),
         url('../fonts/poppins-bold/poppins-bold-webfont.svg#poppinsbold') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'poppinsregular';
    src: url('../fonts/poppins-regular/poppins-regular-webfont.eot');
    src: url('../fonts/poppins-regular/poppins-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/poppins-regular/poppins-regular-webfont.woff2') format('woff2'),
         url('../fonts/poppins-regular/poppins-regular-webfont.woff') format('woff'),
         url('../fonts/poppins-regular/poppins-regular-webfont.ttf') format('truetype'),
         url('../fonts/poppins-regular/poppins-regular-webfont.svg#poppinsregular') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'ralewaybold';
    src: url('../fonts/raleway-bld/raleway-bold-webfont.eot');
    src: url('../fonts/raleway-bld/raleway-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/raleway-bld/raleway-bold-webfont.woff2') format('woff2'),
         url('../fonts/raleway-bld/raleway-bold-webfont.woff') format('woff'),
         url('../fonts/raleway-bld/raleway-bold-webfont.ttf') format('truetype'),
         url('../fonts/raleway-bld/raleway-bold-webfont.svg#ralewaybold') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'ralewayheavy';
    src: url('../fonts/raleway-heavy/raleway-heavy-webfont.eot');
    src: url('../fonts/raleway-heavy/raleway-heavy-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/raleway-heavy/raleway-heavy-webfont.woff2') format('woff2'),
         url('../fonts/raleway-heavy/raleway-heavy-webfont.woff') format('woff'),
         url('../fonts/raleway-heavy/raleway-heavy-webfont.ttf') format('truetype'),
         url('../fonts/raleway-heavy/raleway-heavy-webfont.svg#ralewayheavy') format('svg');
    font-weight: normal;
    font-style: normal;
}