body{
	font-family: $pps-regular;
}
body,
html{
	width:100%;
	min-height:100vh;
}
img[style]{
    max-width: 100%;
    height: auto !important;
}
#app{
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	overflow: hidden;

	.wrapper{
		flex-grow:1;
	}
}
textarea{
	resize: none;
}
figure{
	margin: 0;
}
h1,h2,h3,h4,h5,h6,p{
	margin-bottom: 0;
}
a{
    color: inherit;
}
a:focus,
a:hover{
    color: inherit;
    text-decoration: none;
}
*[data-animate]{
	opacity: 0;
}
.animated[data-animate]{
	opacity: 1;
}
.bg-menu,
.bg-busca{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 999;
	display: none;
	background-color: rgba(#000, 0.6);
}
.title-1{
	font-size: 15px;
	color: $verde;
	line-height: 1.2;
	font-family: $rly-heavy;
	margin-bottom: 25px;
}
.title-2{
	font-family: $pps-bold;
	color: #333;
	line-height: 1.2;
	margin-bottom: 25px;

	@include media-breakpoint-up(md) {
		font-size: 35px;
	}
	@include media-breakpoint-down(sm) {
		font-size: 24px;
	}
}
.desc.editor{
	font-size: 14px;
	line-height: 1.4;

	iframe{
		max-width: 100%;
	}

	a:hover{
		text-decoration: underline;
	}

	p + p{
		margin-top: 14px;
	}
}
.btn-padrao{
	width: 185px;
	height: 48px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 24px;
	background-color: $verde;
	font-family: $rly-bold;
	color: #fff;
	font-size: 13px;
	line-height: 1;
	transition: all 0.3s linear;

	&:hover{
		background-color: $marron;
		color: #fff;
	}
}
#go-topo{
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 995;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    transform: translateY(80px);
    background-color: $marron;
    box-shadow: 0 0 6px rgba(#000, 0.6);
    transition: all .4s ease-out;
    color: #fff;
    font-size: 20px;

    &:hover{
    	background-color: #000;
    	color: #fff;
    }
}
#go-topo.active{
    transform: translateY(0);
}