.internas{

	@include media-breakpoint-up(md) {
		position: relative;
		overflow: hidden;
		z-index: 1;

		&:before,
		&:after{
			content: '';
			position: absolute;
			width: 336px;
			height: 351px;
			background-repeat: no-repeat;
			background-position: center center;
			background-size: 100% 100%;
			background-image: url('../images/triangulo.png');
			z-index: -1;
		}
		&:before{
			left: -100px;
			top: -100px;
		}
		&:after{
			right: -100px;
			bottom: -100px;
		}
	}

	@include media-breakpoint-only(xl) {
		padding: 70px 0;
	}
	@include media-breakpoint-only(lg) {
		padding: 60px 0;
	}
	@include media-breakpoint-only(md) {
		padding: 50px 0;
	}
	@include media-breakpoint-down(sm) {
		padding: 40px 0;
	}

	.pag-header{
		width: 100%;
		max-width: 630px;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 40px;
		.title-1,
		.title-2,
		.desc.editor{
			text-align: center;
		}
	}

	&.pag-quem-somos{

		.title-1{
			display: inline-block;
			position: relative;
			padding-left: 80px;

			&:before{
				content: '';
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				left: 0;
				height: 6px;
				width: 70px;
				background-color: currentColor;
			}
		}

		#accordionQuemSomos{
			margin: 40px 0 30px 0;

			.item + .item{
				margin-top: 5px;
			}
			.item{
				.item-header{
					background-color: $verde;
					color: #fff;
					text-transform: uppercase;
					font-family: $rly-heavy;
					line-height: 1;
					display: flex;
					align-items: center;
					justify-content: space-between;
					flex-wrap: wrap;
					padding: 10px;
					cursor: pointer;

					h3{
						font-size: 15px;
					}

					.arrow{
						width: 23px;
						height: 23px;
						background-repeat: no-repeat;
						background-image: url('../images/arrow.png');
						display: block;
						transition: all 0.2s linear;
					}
				}
				.item-header[aria-expanded="true"] {
					.arrow{
						transform: rotate(180deg);
					}
				}
				.item-body{
					background-color: #eee;

					.content{
						padding: 10px;
						font-size: 13px;
						line-height: 1.4;
						color: #333333;
						text-align: justify;
					}
				}
			}
		}

		@include media-breakpoint-only(xl) {
			.segura-content{
				width: calc(55% - calc(calc(100vw - 1200px)/2));
			}
			.btn-padrao{
				margin-left: auto;
			}
			.segura-imagem{
				width: 45%;
				display: flex;
			}
		}
		@include media-breakpoint-down(lg) {
			.container-fluid{
				width: 100%;
				margin-left: auto;
				margin-right: auto;
				padding-left: 15px;
				padding-right: 15px;

				.segura-content{
					width: 100%;
					margin-bottom: 40px;
				}
				.btn-padrao{
					margin-left: auto;
					margin-right: auto;
				}
				.segura-imagem{
					width: 100%;
					text-align: center;
					padding-left: 15px;
					padding-right: 15px;

					img{
						max-width: 100%;
						height: auto;
					}
				}
			}
		}
		@include media-breakpoint-only(lg) {
			.container-fluid{
				max-width: 970px;
			}
		}
		@include media-breakpoint-only(md) {
			.container-fluid{
				max-width: 720px;
			}
		}
		@include media-breakpoint-only(sm) {
			.container-fluid{
				max-width: 540px;
			}
		}
	}
	//&.pag-quem-somos

	&.pag-servicos{
		.card-servicos{
			background-color: #fff;
			padding: 5px;
			box-shadow: none;
			margin-bottom: 30px;
		}
	}
	&.pag-detalhes{
		.title-1{
			display: inline-block;
			position: relative;
			padding-left: 80px;
			margin-bottom: 15px;

			&:before{
				content: '';
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				left: 0;
				height: 6px;
				width: 70px;
				background-color: currentColor;
			}
		}
		.btn-padrao{
			margin: 30px auto 0 auto;
		}
	}
}

.paginacao{
	list-style-type: none;
	padding-left: 0;
	margin-bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	margin-top: 30px;

	li + li{
		margin-left: 10px;
	}

	.active a{
		background-color: $verde;
	}

	a{
		width: 38px;
		height: 38px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		background-color: #bbb;
		color: #fff;
		font-family: $pps-bold;
		font-size: 16px;
		line-height: 1;
		transition: all 0.3s linear;
	}

	.indicador{
		a{
			color: $verde;
			font-size: 24px;
		}
	}
}

.form-estilos{

	@include media-breakpoint-up(sm) {
		.col-sm-6:first-child,
		.col-sm-3{
			padding-right: 5px;
		}
		.col-sm-6:last-child,
		.col-sm-9{
			padding-left: 5px;
		}
	}

	input{
		height: 48px;
	}
	.form-control{
		background-color: #eee;
		color: #333;
		font-size: 13px;
		border-radius: 0;
		border: 1px solid transparent;

		&::placeholder{
			color: #333;
		}

		&:focus{
			box-shadow: none;
			border-color: $verde;
		}
	}

	.custom-input-file{
		position: relative;

		label{
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			padding: 5px 12px;
			height: 100%;
			display: flex;
			align-items: center;
			background-color: #eee;
			cursor: pointer;
		}

		.output{
			display: block;
			flex-grow: 1;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
			background-color: #eee;
			font-size: 13px;
			padding: 8px 0;
			color: #333;
		}
		.far{
			font-size: 20px;
			line-height: 1;
			color: #888;
		}
	}

	.btn-padrao{
		outline: none;
		border: none;
		font-size: 13px;
		margin: 40px auto 0 auto;
	}
}